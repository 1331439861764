.nav-logo-custom {
	color: #fff;
	font-weight: bold;
	font-size: 30px;
	letter-spacing: 1px;
}

@media screen and (max-width: 980px) {
	.nav-logo-custom {
		font-size: 20px;
	}
}