.custom-language-selector {
	display: flex;
	gap: 10px;
	font-size: 18px;
	margin: 0 10px;
}

.custom-language-selector label {
	display: flex;
	align-items: center;
	cursor: pointer;
	color: #b4aeae;
}

.custom-language-selector input[type="radio"] {
	display: none;
}

.checked-label {
	color: #fff!important;
}