.footer {
	background-color: rgb(19, 16, 211);
	max-height: 600px;
	width: 100%;
	padding: 65px 0 45px;
}

.map-image {
	max-width: 420px;
}

.map-position {
	align-self: center;
}

.footer-text {
	color: #fff;
}
.contacts-rows.row>* {
	padding-left: unset!important;
	padding-right: unset!important;
}
.footer-text .list-group .list-group-item {
	background: transparent;
    border: none;
	color: #fff;
	font-weight: 300;
}

.list-title {
	font-weight: 600!important;
}

@media  screen and (max-width: 980px) {
	.map-image { 
		padding-top: 15px;
	}
	.footer {
		max-height: unset;
		padding: 35px 0 0 0;
	}

	.footer * .list-group, h2 {
		text-align: center;
	}
}

@media  screen and (max-width: 280px) {
	.map-image {
		max-width: 420px;
		max-height: 290px;
	}
}