.main-page-background-image {
	height: 100vh;
	width: 100%;
    background-position: right center;
    background-image: url(../../assets/tom-brunberg-building.jpg);
	opacity: 1;
    transition: opacity 700ms;
	background-size: cover;
	position: fixed;
	z-index: -1;
}

.main-page-background-image::before {
	content: "";
	position: absolute;
	height: 100%;
	top: 0;
	right: 0;
	left: 0;
	width: 100%;
	background-color: rgba(0, 0, 0, 0.4); 
}

.page {
	padding: 5%;
}

.content {
	min-height: 679px;
	max-width: 600px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: self-start;
	height: 100%; 
	color: #fff;
}
.text {
	z-index: 20;
}

.title {
	font-size: 75px;
	font-weight: 600;
}

.description {
	font-size: 45px;
	font-weight: 200;
}
.about {
	background-color: #fff;
	min-height: 679px;
	width: 100%;
}
.content-alternative {
	z-index: 10;
}

.title-alternative {
	padding: 40px 10px;
	color: rgb(0, 0, 0);
}

.card-image-custom {
	width: 130px;
    height: 130px;
	
}

.card {
	border: none;
}

.card-body {
	align-items: center;
	text-align: center;
}

.card-text {
	max-width: 60%;
}

@media screen and (max-width: 980px) {
	.title {
		font-size: 50px;
		font-weight: 400;
	}
	.description {
		font-size: 30px;
	}
}